import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CAnchorNav,
  CFaqList,
  LPlan,
  CBtnList,
} from '../../../components/_index';
import { setLang } from '../../../utils/set-lang';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: (
              <>
                Site Terms
                <br className="u_sp" /> of Use
              </>
            ),
            ja: 'サイト利用規約',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/agreement_terms/kv.png',
              },
              imgSp: {
                src: '/assets/images/agreement_terms/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '規約・約款',
                path: '/agreement_terms/',
              },
            ],
            current: {
              label: 'サイト利用規約',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mtExLarge u_mbLarge p_agreement_terms">
        <LWrap>
          <h2 className="c_headingLv2">サイト利用規約</h2>
          <p className="title">1. ご利用にあたって</p>
          <p className="text u_mbMedium">
            三菱地所ホテルズ＆リゾーツ株式会社（以下「当社」）のウェブサイト「https://www.srph.co.jp/以下のディレクトリー、および各種予約プログラムとメール送信プログラム」（以下「本サイト」）は、当社又はその代理人が運営しております。当社ではお客様のご利用に際し、サイト利用規約を設けさせていただいております。本サイトのご利用の前に、以下のサイト利用規約をお読み下さい。本サイトをご利用いただきました場合、以下の利用規約に同意されたものとみなします。
            本サイト利用規約は予告なく変更することがありますので、必ず最新情報をご確認下さい。
          </p>
          <p className="title">2. サービス内容の変更について</p>
          <p className="text u_mbMedium">
            当社では本サイト上で提供するサービスの内容を予告なく変更することがあります。また、保守作業やシステム障害その他の理由により、予告なく一時的または長期的に本サイトの全部または一部の運営を中止することがあります。
          </p>
          <p className="title">3. 著作権</p>
          <p className="text u_mbMedium">
            本サイトに掲載する情報の著作権は、原則として当社に帰属します。当社の文章による承諾がある場合を除き、本サイトに掲載された情報の二次使用を禁止します。
          </p>
          <p className="title">4. 免責事項</p>
          <p className="text u_mbMedium">
            当社では本サイトに掲載する情報について細心の注意を払っております。しかし当社はその完全性を保障するものではありません。当社の営業に関する情報の詳細は、全て現況を優先いたします。本サイトの利用により、利用者がいかなる損害を受けた場合にも、当社は一切の責任を負いません。また、本サイトの保守作業やシステム障害その他の理由によりサービスが中断し、その結果利用者が損害を被った場合においても、当社は一切の責任をおいません。
          </p>
          <p className="title">5. 本サイトへのリンク</p>
          <p className="text u_mbMedium">
            本サイトへのリンクは原則的に自由です。ただし、各ファイルのURLは予告なしに変更されることがありますので、必ずトップページhttps://www.yrph.com/へリンクしてください。画像のみのリンクやフレーム内への利用はご遠慮ください。また、リンクご希望の場合は、予めその旨を当社までご通知ください。
          </p>
          <p className="title">6. リンク先に対する当社の免責</p>
          <p className="text u_mbMedium">
            本サイトは、本サイト内で提供する情報を補完するため、グループホテル、提携施設そしてその他の第三者のウェブサイトへのリンクを張ることがあります。本サイトからリンクを張っているグループホテル以外の第三者のウェブサイトの内容はそれぞれの責任で管理されるものです。当社はグループホテル以外のリンク先サイトの内容について、あるいはそれらをご利用になったことにより生じた損害について、一切の責任を負いません。
          </p>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
